.previewDialog :global(.mdc-dialog__container) {
  width: 100%;
}

@media (min-width: 592px) {
  .previewDialog :global(.mdc-dialog__surface) {
    max-width: 100% !important;
    width: 80%;
  }

  .textAnalysisPopup :global(.mdc-dialog__surface) {
    max-width: 100% !important;
    width: 95%;
    height: 90%;
  }
}
