.ellaAvatar {
  max-width: 70px;
  margin: auto;
  text-align: center;
}

.ellaMessageElevationCard {
  background-color: var(--mdc-theme-background-light);
  border-radius: 20px;
  margin: 0 20%;
}

@media (max-width: 800px) {
  .ellaMessageElevationCard {
    margin: 0;
  }
}
