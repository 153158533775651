.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--mdc-theme-secondary);
}

.loginContainer {
  max-width: 350px;
  text-align: center;
  padding: 30px;
  background-color: var(--mdc-theme-background);
  border-radius: 0.5rem;
}

.loginSlider {
  margin-bottom: 70px;
  padding: 30px 0;
}

.loginSliderDots :global(li button:before) {
  font-size: 9px !important;
}

.loginLogo {
  margin: auto;
  width: 60%;
  min-height: 110px;
}

.loginIllustration {
  margin: auto;
  width: 30%;
  min-height: 110px;
}

.loginForm :global(.mdc-text-field) {
  width: 100%;
  margin-bottom: 16px;
}

.loginForm button[type='submit'] {
  width: 70%;
  margin-bottom: 16px;
}

.passwordIcon {
  z-index: 2;
  position: absolute;
  bottom: 5px;
  right: 10px;
  color: var(--mdc-theme-text-icon-on-background);
}

.googleImage {
  width: 20px;
  height: 20px;
}

@media (max-width: 520px) {
  .login {
    background-color: var(--mdc-theme-background);
  }
  .loginContainer {
    box-shadow: none;
  }
}

@media (min-width: 520px) {
  :global(.slick-prev):hover:before,
  :global(.slick-prev):focus:before,
  :global(.slick-next):hover:before,
  :global(.slick-next):focus:before {
    opacity: 1 !important;
    color: var(--mdc-theme-primary) !important;
  }

  :global(.slick-prev:before),
  :global(.slick-next:before) {
    opacity: 0.25 !important;
    color: black !important;
  }

  :global(.slick-prev:before) {
    content: '❮' !important;
  }
  :global(.slick-next:before) {
    content: '❯' !important;
  }
}
