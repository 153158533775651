.ellaAvatar {
  font-size: 7rem;
  max-width: 100%;
}

.avatarCell {
  text-align: center;
}

.answersGrid {
  padding-top: 0;
}

.answerCard {
  padding: 10px;
  margin: 0 10px;
  border-radius: 0.5rem;
  text-align: center;
  height: 90%;
  cursor: pointer;
}

.answerCard:hover {
  box-shadow: 0px 0px 0px 1px var(--mdc-theme-primary) inset;
}

.answerIlustration {
  max-height: 56px;
  margin: 20px 20px 0 20px;
}

.answerTextWithSubtitle {
  margin-bottom: 0;
}

.answerSubtitle {
  margin-top: 0;
  font-size: 0.7rem;
  line-height: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.0071428571em;
}

@media (max-width: 680px) {
  .ellaAvatar {
    font-size: 5rem;
  }

  :global(.mdc-dialog .mdc-dialog__surface) {
    max-width: 100%;
    max-height: 100%;
  }

  .startHeader :global(.mdc-typography--headline5) {
    font-size: 100%;
  }

  .startHeader h1 {
    margin-block-start: 0;
    margin-block-end: 0.47em;
  }

  .startHeader p {
    font-size: 85%;
    margin: 0;
  }

  .answersGrid {
    padding: 0;
  }

  .answerCard {
    padding: 2px;
    margin: 0;
    height: 97%;
  }

  .answerCard :global(.mdc-layout-grid__inner) {
    grid-gap: 10px;
  }

  .answerIlustration {
    max-height: 46px;
    margin: 15px 15px 0 15px;
  }
}

@media (max-width: 375px) {
  .ellaAvatar {
    font-size: 4rem;
  }

  .startHeader {
    padding: 0 0 10px 0;
  }

  .startHeader h1 {
    margin-block-end: 0.27em;
  }

  .answerCard {
    padding: 0;
    margin: 0;
    height: 100%;
  }

  .answerIlustration {
    max-height: 42px;
    margin: 10px 10px 0 10px;
  }
}
