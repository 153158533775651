.breadcrumbWrap {
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
}

@media print {
  .breadcrumbWrap {
    display: none !important;
  }
}

.breadcrumbItem {
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
}

.breadcrumbItem a:-webkit-any-link {
  text-decoration: none;
  color: inherit;
}

.breadcrumbSeparator {
  display: flex;
  padding: 0;
  user-select: none;
  align-items: center;
}

.breadcrumbSeparator i {
  font-size: 18px;
}
