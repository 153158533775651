.textEditor {
  height: auto;
  margin: 8px 1px 1px 0;
  padding: 0 16px 16px;
  line-height: 1.75rem;
  background-color: whitesmoke;
}

.radioImages :global(.mdc-radio__background) {
  display: none;
}

.radioImages :global(.mdc-radio__ripple) {
  display: none;
}

.radioImages :global(.mdc-radio) {
  position: absolute;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.radioImageElevation {
  border-radius: 0.5rem;
  cursor: pointer;
  margin-right: 15px;
}

.radioImageElevation:hover {
  box-shadow: 0px 0px 0px 2px var(--mdc-theme-primary) inset;
}

.radioImageElevation:global(.selected) {
  box-shadow: 0px 0px 0px 2px var(--mdc-theme-primary) inset;
}

.radioImageElevation img {
  max-height: 40px;
  max-width: 85px;
  margin: 11px 11px 0 11px;
}

.radioImageElevationCaption {
  text-align: center;
  margin-top: -5px;
}

.radioIconElevation {
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 8px;
}

.radioIconElevation:hover {
  box-shadow: 0px 0px 0px 2px var(--mdc-theme-primary) inset;
}

.radioIconElevation i {
  margin: 5px;
}

.stepsPreview p {
  margin: 0;
}
