.overviewCard {
  border-radius: 0.5rem;
  padding: 30px;
}

.howCommonCard {
  border-radius: 0.5rem;
  background-color: var(--mdc-theme-yellow);
  padding: 30px;
}

.howCommonImage {
  margin-left: -40px;
  margin-bottom: -30px;
  width: 100%;
}

.overviewTitles {
  text-transform: uppercase;
}

.levelCard {
  border-radius: 0.5rem;
  border-color: var(--mdc-theme-on-surface);
  border-style: solid;
  border-width: 1px;
  padding: 30px 10px 10px 10px;
  min-height: 100px;
}

.levelCardTitle {
  width: 85%;
  padding-top: 10px;
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  justify-content: flex-end;
}

.levelCardTitleIcon {
  width: 24px;
  height: 24px;
  border-radius: 100px;
}

.levelCardTitle i {
  padding-left: 10px;
}

.levelLine {
  min-width: 140px;
  width: 123%;
  height: 20px;
}

.awarenessCard {
  margin: 10px;
}

.awarenessCardMedia {
  background-color: var(--mdc-theme-background-light);
  padding: 40px;
  min-height: 310px;
  display: inline-flex;
  vertical-align: top;
  align-items: center;
}
