.welcomeSectionGrid {
  background-color: var(--mdc-theme-background-light);
  border-radius: 0.5rem;
  margin-top: 50px;
}

.welcomeIllustrationCell img {
  margin-top: -90px;
  margin-left: -35px;
  max-width: 400px;
}

.welcomeHeaderCells {
  padding-left: 40px;
}

.welcomeHeaderCells h2 {
  margin-top: 0;
  margin-bottom: 0;
}

@media (max-width: 839px) {
  .welcomeIllustrationCell img {
    margin-top: -60px;
    margin-left: 10px;
  }
}
