.StartModal .mdc-dialog__container {
    width: 100%;
}

@media (min-width: 592px) {
    .StartModal .mdc-dialog__surface {
        max-width: 100% !important;
        width: 80%; 
    }
}
