.skip {
  position: absolute;
  top: auto;
  width: 200px;
  height: 40px;
  overflow: hidden; 
  background: #F15A24;
  left: 0%;
  padding: 5px;
  opacity:  0;
}

.skip:focus {
  opacity: 1.0;
}