body {
  margin: 0;
  font-family: 'PT Sans', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* pallet colors:
    #19A2A2 - primary
    #74CAC2 - secondary
    #E724B6 - pink
    #A21980 - dark pink
    #FFD974 - yellow - old #E7C024
    --mdc-theme-primary: #19a2a2; old
 */

  --mdc-theme-primary: #F15A24;
  --mdc-theme-secondary: #4C2A82;
  --mdc-theme-background: #fff;
  --mdc-theme-background-light: #f2f6ff;
  --mdc-theme-surface: #fff;
  --mdc-theme-error: #b00020;
  --mdc-theme-error-light: #fe6984;
  --mdc-theme-on-primary: #fff;
  --mdc-theme-on-secondary: #fff;
  --mdc-theme-on-surface: #303030;
  --mdc-theme-on-error: #fff;

  --mdc-theme-pink: #e724b6;
  --mdc-theme-dark-pink: #a21980;
  --mdc-theme-yellow: #ffd974;
  --mdc-theme-green-enabled: #74c573;
  --mdc-theme-grey-disabled: #f2f6ff;

  --mdc-typography-button-text-transform: none;
  --mdc-typography-button-font-size: 1rem;

  --colors-hue-gray-0: #f8f9fa;
  --colors-hue-gray-1: #f1f3f5;
  --colors-hue-gray-2: #e9ecef;
  --colors-hue-gray-3: #dee2e6;
  --colors-hue-gray-4: #ced4da;
  --colors-hue-gray-5: #adb5bd;
  --colors-hue-gray-6: #868e96;
  --colors-hue-gray-7: #495057;
  --colors-hue-gray-8: #343a40;
  --colors-hue-gray-9: #212529;

  /* 

  --mdc-theme-text-primary-on-background: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-light: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-dark: white;
  --mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-dark: rgba(255, 255, 255, 0.5); */
}

/* Override Material Design Roboto Font */
body [class^='mdc-']:not(.rmwc-icon),
body [class*='mdc-']:not(.rmwc-icon) {
  font-family: 'PT Sans', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.mdc-layout-grid__cell:focus {
  outline: none;
}

a.mdc-ripple-upgraded.mdc-list-item.active::before {
  opacity: 0.12;
}

/* Remirror text editor */
.remirror-theme {
  /* Remiror Text editor colors */
  --rmr-color-primary: var(--mdc-theme-primary) !important;
  --rmr-color-secondary: var(--mdc-theme-secondary) !important;
}

/* Show mentions tags popup over other fields */
.remirror-floating-popover {
  z-index: 2;
}

.remirror-mention-atom-popup-item {
  max-width: 300px !important;
  width: 300px !important;
}

.remirror-mention-atom.remirror-mention-atom-at {
  background: var(--colors-hue-gray-2);
  font-weight: bold;
  font-size: 0.9em;
  font-style: normal;
  border-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
  color: var(--mdc-theme-primary);
}

/* Form validation messages */

form * > div[role='alert'],
.field-validation-text {
  color: var(--mdc-theme-error);
  padding: 5px;
}

form * > div[role='alert']::before,
.field-validation-text::before {
  display: inline;
  content: '⚠ ';
  vertical-align: middle;
}

.mdc-text-field--invalid,
.mdc-select--invalid {
  border-left: 3px solid var(--mdc-theme-error);
}

/* Class added to react-select autocomplete */
.material-ui-select * > input {
  height: 40px;
}

/* Style used to show warning when start a new journey */
.select-field-warning * > .rmwc-icon {
  color: var(--mdc-theme-error) !important;
  opacity: 1 !important;
}

@media print {
  aside {
    display: none !important;
  }
  .mdc-drawer-app-content {
    margin-left: 0 !important;
  }
  header {
    display: none !important;
  }
  .mdc-top-app-bar--fixed-adjust {
    padding-top: 0 !important;
  }
  .no-print {
    display: none !important;
  }
}
